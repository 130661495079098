.QuizContainer{
  padding: 10px 20px;
}

.QuizContent{
  border: 1px solid #8CABC6;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.QuizCount{
  color: #AEAEAE;
  font-size: 12px;
  font-weight: bold;
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
}

.QuizContent h2{
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 0px;
  padding-right: 20px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.QuizContent ul{
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 0px;
  padding-right: 20px;
  list-style: none;
  margin: 0;
}

.QuizContent ul li{
  border: 1px solid #AEAEAE;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  margin: 10px 0px;
  position: relative;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.QuizContent ul li input{
  margin: 0;
  width: 100%;
  height: 100%;
}

.QuizContent ul li label{
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  margin: 0;
}

.QuizActive{
  background-color : #C6D5E3;
  border : 1px solid #8CABC6;
}


/*
-------------------------------------
      BOOTSTRAP XS
-------------------------------------
*/
@media (max-width: 767px){

}

/*
-------------------------------------
      BOOTSTRAP SM
-------------------------------------
*/
@media (min-width: 768px) and (max-width: 991px){

}

/*
-------------------------------------
      BOOTSTRAP MD
-------------------------------------
*/
@media (min-width: 992px) and (max-width: 1199px){

}

/*
-------------------------------------
      BOOTSTRAP LG
-------------------------------------
*/
@media (min-width: 1200px){

}
