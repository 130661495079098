.assessmentWrapper{
  padding: 10px 20px;
}

.assessmentContent{
  border: 1px solid #14558f;
  background-color: #14558f;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 5px 0px;
}

.assessmentTitle{
  border-bottom: 1px solid #fff;
  margin: 10px;
  padding: 0px 5px 10px 5px;
}

.assessmentTitle::after{
  content: ">";
  font-size: 26px;
  line-height: 26px;
  font-weight: 700;
  display: inline;
  float: right;
  color: #fff;
  font-family: serif;
}

.assessmentTitle p{
  font-weight: 700;
  font-size: 13px;
  line-height: 26px;
  display: inline-block;
  margin: 0;
  color: #fff;
}

.assessmentText p{
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  color: #fff;
  margin: 10px;
  padding-top: 10px;
  padding-right: 40px;
  padding-left: 5px;
}


/*
-------------------------------------
      BOOTSTRAP XS
-------------------------------------
*/
@media (max-width: 767px){

}

/*
-------------------------------------
      BOOTSTRAP SM
-------------------------------------
*/
@media (min-width: 768px) and (max-width: 991px){

}

/*
-------------------------------------
      BOOTSTRAP MD
-------------------------------------
*/
@media (min-width: 992px) and (max-width: 1199px){

}

/*
-------------------------------------
      BOOTSTRAP LG
-------------------------------------
*/
@media (min-width: 1200px){

}
