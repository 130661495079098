* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding-top: 0;
  background: #f2f2f2 !important;
  font-family: sans-serif;
}

.container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

@media (min-width: 769px) {
  .container {
    margin: 2.5rem auto;
  }
}

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.answerOptions {
  margin: 0;
  padding: 0;
  list-style: none;
}

.answerOptions1 {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* QuestionCount Component */
.questionCount {
  padding: 1.5rem 2.5rem 0;
  font-size: 14px;
}

/* Question Component */
.question {
  margin: 0;
  padding: 0.5rem 2.5rem 1.5rem 2.5rem;
}

/* AnswerOption Component */
.answerOption {
  border-top: 1px solid #eee;
}

.answerOption:hover {
  background-color: #eefbfe;
}

.radioCustomButton {
    position: absolute;
    width: auto;
    opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.radioCustomLabel {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 1.5rem 2.5rem 1.5rem 5rem;
    font-size: 16px;
    line-height: 1.5;
}

.radioCustomButton ~ .radioCustomLabel:before {
    position: absolute;
    top: 20px;
    left: 38px;
    width: 28px;
    height: 28px;
    content: '';
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    border: 1px solid #bbb;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
    content: '';
    background: #8BC53F url(./svg/icon-check.svg) no-repeat;
    background-size: 27px;
    border-color: #8BC53F;
}

/* Animation */
.fade-enter {
    opacity: 0;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out 0.3s;
    -webkit-transition: opacity 0.5s ease-in-out 0.3s;
    -moz-transition: opacity 0.5s ease-in-out 0.3s;
    -o-transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
}

.fade-leave.fade-leave-active {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
}

.fade-appear {
    opacity: 0;
}

.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
}

.button {
    position: relative;
    background-color: #19c589;
    border: none;
    float: right;
    font-size: 28px;
    color: #FFFFFF;
    padding: 20px;
    width: 200px;
    margin: 20px;
    text-align: center;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}

.button:after {
    content: "";
    background: #f1f1f1;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
}

.button:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
    -moz-transition: 0s;
    -webkit-transition: 0s;
    -o-transition: 0s;
}

.accordion {
    background-color: #095696;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    height: 100%;
    border: none;
    text-align: left;
    font-size: 20px;
    outline: none;
    transition: 0.4s;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
}

.active, .accordion:hover {
    background-color: #3b92db;
}

.accordion:after {
    content: '\002B';
    color: white;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}


.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    font-family: sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    overflow: auto;
    color: #437399;
    transition: max-height 0.2s ease-out;
    -moz-transition: max-height 0.2s ease-out;
    -o-transition: max-height 0.2s ease-out;
    -webkit-transition: max-height 0.2s ease-out;
}

.accordion1 {
    background-color: #095696;
    color: white;
    padding: 18px;
    width: 100%;
    height: 80px;
    height: 100%;
    border: none;
    text-align: left;
    font-size: 20px;
    outline: none;
    transition: 0.4s;
}

.panel1 {
    padding: 0 18px;
    background-color: white;
    font-family: sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    overflow: auto;
    color: #437399;
    transition: max-height 0.2s ease-out;
}

.btn {
  position: relative;
  border: 2px solid black;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  width: 100%;
  background-color: white;
  color: black;
  padding: 14px 28px;
  font-size: 16px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

/* Green */
.category {
  border-color: #19c589;
  color: green;
}

.category:hover {
  background-color: #19c589;
  color: white;
}

.topRowContainer {
  margin: 0 !important;
  padding: 0;
  background-color: #14558f;
  border-bottom: 2px solid #11416D;
  padding: 0px 50px;
  position: relative;
  height: 75px;
}

.topRow {
  min-height: 20px;
  float: left;
  width: 30%;
  overflow: hidden;
  background-color: #14558F;
  height: 100%;
  display: inline-block;
  position: relative;
  padding: 0 !important;
}

.topRow1 {
  min-height: 20px;
  float: left;
  width: 70%;
  height: 100%;
  display: inline-block;
  background-color: #14558F;
}

/*Strip the ul of padding and list styling*/
.menu {
    height: 100%;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    display: flex;
}
/*Create a horizontal list with spacing*/
.lis {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
}
/*Style for menu links*/
.lis a {
    display:block;
    min-width:140px;
    height: 79px;
    text-align: center;
    line-height: 79px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    color: white;
    background: #0000;
    text-decoration: none;
    cursor: pointer;
}


/*Hover state for top level links*/
.lis:hover a {
  border-bottom: 3px solid #baccdd;
  color: #baccdd;
  text-decoration: none;
}

.lis a.active {
  border-bottom: 3px solid #baccdd;
  color: #baccdd;
}


/*Style 'show menu' label button and hide it by default*/
.show-menu {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: none;
    color: white;
    background: #baccdd;
    text-align: center;
    padding: 10px 0;
    display: none;
    position: relative;
    cursor: pointer;
}
/*Hide checkbox*/
input[type=checkbox]{
    display: none;
}
/*Show menu when invisible checkbox is checked*/
input[type=checkbox]:checked ~ #menu{
    display: block;
}

.topDivH {
  float: left;
  text-align: left;
  color: #fff;
  font-family: sans-serif;
  font-size: 16px;
  margin: 0;
  line-height: 75px;
  font-weight: normal;
  padding-right: 1px;
}

.topDivH a:hover{
  text-decoration: none;
}

.topDivHL {
  float: left;
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 75px;
  width: 75px;
  padding: 10px;
  padding-left: 0px;
  margin-left: 0px;
  border-bottom: none;

}


.button1 {
    position: relative;
    background: #fff;
    border: 3px solid #388557;
    color: #388557;
    float: left;
    width: 150px;
    font-size: 18px;
    height: 44px;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
    -webkit-transition-duration: unset; /* Safari */
    transition-duration: unset;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}

.button1:after {
    content: "";
    background: #f1f1f1;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: 0s;
    -webkit-transition: 0s;
    -moz-transition: 0s;
}

.button1:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.button3{
  position: relative;
  background: #388557;
  border: none;
  color: #fff;
  float: left;
  font-size: 18px;
  width: 150px;
  height: 44px;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  -webkit-transition-duration: unset; /* Safari */
  -moz-transition-duration: unset; /* Safari */
  -o-transition-duration: unset; /* Safari */
  transition-duration: unset;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.button3:after {
    content: "";
    background: #f1f1f1;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: 0s;
    -webkit-transition: 0s;
    -moz-transition: 0s;
    -o-transition: 0s;
}

.button3:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
    -webkit-transition: 0s;
    -o-transition: 0s;
    -moz-transition: 0s;
}

.btn-group .button1:not(:last-child) {
    border-right: none; /* Prevent double borders */
}
.btn-group .button1:hover {
    background-color: #708090;
}


.btn-group .button1 {
    background-color: #19c589;
    border: 1px solid green;
    color: white;
    padding-right: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    cursor: pointer;
    align-items: center;
}



.ButBar {
  background-color: inherit;
  height: 100px;
  width: 100%;
}

.buttonContainer {
  padding-left: 0;
}


.button2 {
    position: relative;
    background-color: #19c589;
    border: none;
    font-size: 18px;
    color: #FFFFFF;
    width: 200px;
    height: 50px;
    text-align: center;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}

.button2:after {
    content: "";
    background: #f1f1f1;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -o-transition: all 0.8s;
}

.button2:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
    -webkit-transition: 0s;
    -moz-transition: 0s;
    -o-transition: 0s;
}

.prepareLink{
  color : #14558f;
  font-weight : bold;
  text-decoration : underline;
}

.button4{
  display: block;
  position: relative;
  width: 100%;
  background-color: #14558f;
  border: 2px solid #11416D;
  font-size: 28px;
  color: #fff;
  padding: 14px;
  text-align: left;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  margin: 20px 0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.button4 h1{
  color: #fff;
  font-size: 16px;
  margin-top: 0px;
  font-weight: bold;
  margin-bottom: 5px;
}

.button4 p{
  color: #fff;
  font-size: 14px;
  margin-bottom: 0px;
}

.Desc-Image {
  width: 100%;
  float: left;
}

.homeInstruction{
  position: absolute;
  width: 100%;
  padding: 0px 50px;
  left: 0;
  bottom: 0px;
}

.HomeHead {
  width: 512px;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  font-family: sans-serif;
  padding-left: 0;
  text-align: left;
  padding-top: 100px;
  font-weight: 400;
  margin-bottom: 25px;
  letter-spacing: 1px;
}

.Head{
  width: 512px;
  font-weight: normal;
  font-size: 55px;
  line-height: 60px;
  font-family: sans-serif;
  padding-left: 0;
  text-align: left;
  padding-top: 100px;
  font-weight: 400;
  margin-bottom: 25px;
}

.HomeHead1 {
  width: 512px;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  font-family: sans-serif;
  margin-bottom: 40px;
  text-align: left;
  padding-top: 0;
  padding-left: 0;
}

.disc {
  padding-left: 370px;
  padding-right: 370px;
  font-weight: normal;
  font-size: 17px;
}

.Qquestions {
  margin-top: 100px;
  padding-left: 25%;
  padding-right: 25%;
}

.toggle-box {
  display: none;
}

.toggle-box + label {
  cursor: pointer;
  display: block;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 5px;
}

.toggle-box + label + div.a {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.a {
  display: block;
}

.toggle-box + label + div.b {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.b {
  display: block;
}

.toggle-box + label + div.c {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.c {
  display: block;
}

.toggle-box + label + div.d {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.d {
  display: block;
}

.toggle-box + label + div.e {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.e {
  display: block;
}

.toggle-box + label + div.f {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.f {
  display: block;
}

.toggle-box + label + div.g {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.g {
  display: block;
}

.toggle-box + label + div.h {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.h {
  display: block;
}

.toggle-box + label + div.aa {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.aa {
  display: block;
}

.toggle-box + label + div.bb {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.bb {
  display: block;
}

.toggle-box + label + div.cc {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.cc {
  display: block;
}

.toggle-box + label + div.dd {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.dd {
  display: block;
}

.toggle-box + label + div.ee {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.ee {
  display: block;
}

.toggle-box + label + div.ff {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.ff {
  display: block;
}

.toggle-box + label + div.gg {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.gg {
  display: block;
}

.toggle-box + label + div.hh {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.hh {
  display: block;
}

.toggle-box + label + div.ii {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.ii {
  display: block;
}

.toggle-box + label + div.jj {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div.jj {
  display: block;
}

.toggle-box + label:before {
  background-color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: #437399;
  display: block;
  white-space: nowrap;
  content: "v Show More";
  float: left;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  text-align: center;
  width: 20px;
}

.toggle-box:checked  + label:before {
  color: #437399;
  white-space: nowrap;
  content: "^ Show Less";
}


/*Responsive Styles*/
@media screen and (max-width : 991px){
    /*Make dropdown links appear inline*/
    .menu {
        position: relative;
        display: none;
    }
    /*Create vertical spacing*/
    .lis {
        margin-bottom: 1px;
    }
    /*Make all menu links full width*/
    .lis, .lis a {
        width: 100%;
    }
    /*Display 'show menu' link*/
    .show-menu {
        display:block;
    }
    .HomeHead1 {
        width: 100%;
        padding-right: 5%;
    }
    .ButBar {
        width: 100%;
    }
    .disc {
      width: 100%;
      padding: 0;
      margin-right: auto;
      margin-left: auto;
      display : block;
    }
    .Qquestions {
      padding: 0;
      width: 100%;
    }
}
