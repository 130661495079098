.App {
  text-align: center;
  padding-top: 0;
  padding: 0px 50px;
  background-color: inherit !important;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 80px;
}

/*.App-header {
  background-color: #222;
  height: 70px;
  padding-top: 100px;
  padding-left: 20px;
  color: white;
}*/

.HomeBR{
  display: none;
}

.prepareBlueBoxContainer{
  padding: 0 !important;
}

.prepareBlueBox{
  border-top : 0 !important;
}

.massTitle{
  text-align: center;
  padding-top: 30px;
  padding-bottom: 15px;
}

.massTitle p{
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  display: inline-block;
  border-bottom: 2px solid #F5C436;
  padding-bottom: 5px;
}

.massBlueBox{
  border-top: 0px !important;
  padding: 20px !important;
}

.massBlueBox hr{
  border-top: 1px solid #AEAEAE;
}

.resources-ul{
  padding: 0 50px;
  list-style: none;
  margin: 0;
  padding-top: 10px;
}

.resources-ul li{
  padding-left: 20px;
}

.resources-ul li p{
  color: #fff;
  margin: 0;
  font-weight: normal;
  padding-bottom: 20px;
  font-size: 18px;
}

.modalClose{
  font-size: 30px;
  color: #AEAEAE;
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: serif;
  font-weight: bold;
  line-height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.header .modalCategory{
  color: #AEAEAE;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin: 0;
}

.header p{
  font-size: 14px;
  color: #000;
  font-weight: bold;
  text-align: center;
}

.modalImg{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  margin-top: 14px;
  margin-bottom: 14px;
}

.App-title {
  background-color: #222;
  font-size: 1.5em;
  text-align: left;
  float: left;
  padding-top: 50px;
  padding-left: 20px;
  color: white;
}

.App-title1 {
  font-size: 1.0em;
  color: white;
  float: left;
  text-align: left;
  padding-left: 20px;
  width: 100%;
  background-color: #222;
  height: 50px;
}

.App-intro {
  padding: 10px;
  font-size: large;
}

.asse {
  font-size: 40px;
  align-content: center;
  width: 100%;
  float: center;
}

.regularText{
  text-align: left;
  font-size: 13px;
  margin-bottom: 6px;
}

.prep {
  padding: 150px;
  padding-bottom: 20px;
  width: 100%;
  height: 100vh;
  justify-content: center;
  font-weight: normal;
  background-color: #eee;
  font-family: sans-serif;
  font-size: 30px;
}

.prepX {
  padding-top: 150px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-weight: normal;
  background-color: #eee;
  font-family: sans-serif;
  font-size: 30px;
}

.prep1 {
  padding-left: 150px;
  padding-right: 150px;
  padding-top: 80px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  font-weight: normal;
  font-family: sans-serif;
  font-size: 30px;
}

/*@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}*/

.collap {
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 50px;
  padding-top: 50px;
  background-color: white;
  margin-left: 120px;
  margin-right: 120px;
  border: 5px solid #6997bc;
}

.popup-overlay{
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(174, 174, 174, 0.95) !important;
  display: flex;
  z-index: 999;
}

.popup-content{
  position: relative;
  background: rgb(255, 255, 255);
  width: 90%;
  margin: auto;
  border: 1px solid #EAEAEA !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  padding: 5px;
  max-width: 600px;
  height: 75%;
  overflow: auto;
}

.actions{
  text-align: center;
  padding-top: 20px;
}

.clearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.clearfix {
    display: inline-block;
}

html[xmlns] .clearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}

/*Responsive Styles*/
@media screen and (max-width : 991px){
    .asse {
      width: 100%;
    }
    .prep {
      padding: 0;
      margin-top: 10px;
      height: 100vh;
    }
    .prepX {
      padding: 0;
      padding-right: 10px;
      padding-left: 10px;
      margin-top: 10px;
      height: 100vh;
    }
    .prep1 {
      padding: 0;
      padding-left: 20px;
      padding-right: 20px;
    }
    .collap {
      padding: 0;
      margin: 0;
    }
}
